
import './App.css';
import { Home } from './pages/Home';

function App() {
  return (
    <div className="App">
      <div>
        <Home />
      </div>
    </div>
  );
}

export default App;
